define("cb-ui/models/proposal", ["exports", "ember-data", "moment", "ember-api-actions"], function (_exports, _emberData, _moment, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    cloneProposal: (0, _emberApiActions.memberAction)({
      path: 'clone-proposal/',
      type: 'post'
    }),
    reinitiateTechnicalProposalEvaluations: (0, _emberApiActions.memberAction)({
      path: 'reinitiate-technical-proposal-evaluations/',
      type: 'post'
    }),
    reinitiatePitchProposalEvaluations: (0, _emberApiActions.memberAction)({
      path: 'reinitiate-pitch-proposal-evaluations/',
      type: 'post'
    }),
    quote: belongsTo('quote', {
      async: true
    }),
    consultantUser: belongsTo('user', {
      async: true,
      inverse: 'proposals'
    }),
    company: belongsTo('company', {
      async: true
    }),
    questions: _emberData.default.hasMany('question', {
      async: true
    }),
    status: attr('string'),
    statusAlias: Ember.computed('id', 'status', 'intl.locale.[]', function () {
      var projectStatus = this.get('status');
      return this.get('intl').t('projectStatuses.' + projectStatus).toString();
    }),
    invitationSentDate: attr('date'),
    cancelReason: attr(),
    isCancelReasonBlank: Ember.computed('cancelReason', function () {
      return !!Ember.isEmpty(Ember.$.trim(this.get('cancelReason')));
    }),
    projectName: null,
    projectId: null,
    //status timestamps
    acceptedAt: attr('date'),
    concludedAt: attr('date'),
    // quote owner name
    ownerName: null,
    //quote company name
    companyName: null,
    industryTitle: null,
    serviceTitle: null,
    metadata: attr(),
    documents: hasMany('proposal-document', {
      async: true,
      inverse: 'proposal'
    }),
    references: hasMany('reference', {
      async: true,
      inverse: 'proposal'
    }),
    teamMembers: hasMany('team-member', {
      async: true,
      inverse: 'proposal'
    }),
    workPackages: hasMany('work-package', {
      async: true,
      inverse: 'proposal'
    }),
    proposalEvaluations: hasMany('proposal-evaluation', {
      async: true,
      inverse: 'proposal'
    }),
    //Financial Quotation
    timeMaterials: hasMany('time-material', {
      async: true,
      inverse: 'proposal'
    }),
    timeMaterialSupplements: hasMany('time-material-supplement', {
      async: true,
      inverse: 'proposal'
    }),
    fixedFees: hasMany('fixed-fee', {
      async: true,
      inverse: 'proposal'
    }),
    cancelParticipationReason: attr('string'),
    //Financial Quotation
    timeMaterialsRebate: attr('number'),
    timeMaterialsRetainer: attr('number'),
    timeMaterialsPaymentPattern: attr('string'),
    timeMaterialsPaymentTerms: attr('string'),
    durationInWeeks: attr('number'),
    fixedFeeRebate: attr('number'),
    fixedFeeRetainer: attr('number'),
    fixedFeePaymentPattern: attr('string'),
    fixedFeePaymentTerms: attr('string'),
    financialQuotationTotal: attr('number'),
    totalMandaysPlannedOverall: attr('number'),
    financialQuotationEvaluationData: attr(),
    finalProposalTotal: attr('number'),
    finalProposalTotalHistory: attr('string'),
    arithmeticAndWeightedAverageDailyRate: attr(),
    //Beauty Contest Invitation related fields
    // invitation sent, accepted
    beautyContestInvitationStatus: attr('string'),
    beautyContestInvitationText: attr('string'),
    beautyContestInvitationAddress: attr('string'),
    beautyContestInvitationDate: attr('date'),
    beautyContestInvitationStartTime: attr('string'),
    beautyContestInvitationEndTime: attr('string'),
    //Intermediate Evaluations Scores
    intermediateScoreTechnicalProposal: attr('number'),
    intermediateScoreBeautyContest: attr('number'),
    //flags to know if proposal is completed by consultant
    consultantCompletedProposal: attr('boolean', {
      defaultValue: false
    }),
    ignoreTimelineValidation: attr('boolean'),
    saveForSubmit: attr('boolean', {
      defaultValue: false
    }),
    consultantFirstName: attr('string'),
    consultantLastName: attr('string'),
    consultantCompanyName: attr('string'),
    consultantEmail: attr('string'),
    consultantAddressLineOne: attr('string'),
    consultantAddressLineTwo: attr('string'),
    consultantAddressLineThree: attr('string'),
    consultantPostalCode: attr('string'),
    consultantCity: attr('string'),
    // fee related fields
    feePercentageUponParticipation: attr('number'),
    feePercentageUponWinning: attr('number'),
    feeFlatForParticipation: attr('number'),
    feeCap: attr('number'),
    feeConditions: attr('string'),
    comment: attr('string'),
    technicalEvaluationIsActive: attr('boolean'),
    pitchEvaluationIsActive: attr('boolean'),
    isActive: attr('boolean'),
    memberEmails: attr('string'),
    dontSendFeedback: attr('boolean'),
    feedback: attr('string'),
    keepEvaluationsAfterDismissal: attr('boolean'),
    participationConfirmed: attr('boolean'),
    quoteObserver: Ember.on('init', Ember.observer('quote', 'status', function () {
      var _this = this;

      this.get('quote').then(function (quote) {
        if (quote) {
          _this.set('projectId', parseInt(quote.get('id'), 10));

          var proposalStatus = _this.get('status');

          var validStatuses = ['accepted', 'submitted', 'lost', 'awarded', 'on hold'];

          if (validStatuses.includes(proposalStatus)) {
            _this.set('projectName', quote.get('projectName'));

            _this.set('ownerName', quote.get('ownerName')); //TODO: Improve


            _this.set('companyName', quote.get('companyName'));
          } else {
            _this.set('projectName', 'to be disclosed');

            _this.set('ownerName', 'to be disclosed');

            _this.set('companyName', 'to be disclosed');
          }

          quote.get('industryStandard').then(function (industry) {
            _this.set('industryTitle', industry.get('industryEn'));
          });
          quote.get('serviceStandard').then(function (service) {
            _this.set('serviceTitle', service.get('serviceEn'));
          });
        }
      });
    })),
    proposalEvaluationsWithoutBeautyItem: Ember.computed.filterBy('proposalEvaluations', 'hasBeautyItem', false),
    proposalEvaluationsWithBeautyItem: Ember.computed.filterBy('proposalEvaluations', 'hasBeautyItem', true),
    notInvitedToBeautyContest: Ember.computed('beautyContestInvitationStatus', function () {
      return this.get('beautyContestInvitationStatus') === 'not invited';
    }),
    notYetInvitedToBeautyContest: Ember.computed('beautyContestInvitationStatus', function () {
      return !this.get('beautyContestInvitationStatus') || !this.get('beautyContestInvitationStatus').trim();
    }),
    invitedToBeautyContest: Ember.computed.equal('beautyContestInvitationStatus', 'invitation sent'),
    acceptedInvitationBeautyContest: Ember.computed.equal('beautyContestInvitationStatus', 'accepted'),
    declinedInvitationBeautyContest: Ember.computed.equal('beautyContestInvitationStatus', 'declined'),
    isIncludedInEvaluation: Ember.computed('status', function () {
      var proposalStatus = this.get('status');
      var validStatuses = ['submitted', 'awarded', 'lost'];
      return validStatuses.includes(proposalStatus) && (!this.get('quote.closedEnvelope') || this.get('quote.closedEnvelope') && this.get('quote.proposalDueDate') < new Date());
    }),
    isClosedEnvelope: Ember.computed('status', function () {
      var proposalStatus = this.get('status');
      var validStatuses = ['submitted'];
      return validStatuses.includes(proposalStatus) && this.get('quote.closedEnvelope') && this.get('quote.proposalDueDate') > new Date();
    }),
    isDraftInvitedOrAccepted: Ember.computed('status', function () {
      var proposalStatus = this.get('status');
      var validStatuses = ['invited', 'accepted', 'draft'];
      return validStatuses.includes(proposalStatus);
    }),
    isDraftOrInvited: Ember.computed('status', function () {
      var proposalStatus = this.get('status');
      var validStatuses = ['invited', 'draft'];
      return validStatuses.includes(proposalStatus);
    }),
    isInvited: Ember.computed.equal('status', 'invited'),
    isSubmitted: Ember.computed.equal('status', 'submitted'),
    isDeclined: Ember.computed.equal('status', 'declined'),
    isAccepted: Ember.computed('status', 'participationConfirmed', function () {
      var status = this.get('status');
      var participationConfirmed = this.get('participationConfirmed');
      return status === 'accepted' && participationConfirmed;
    }),
    isOpened: Ember.computed('status', 'participationConfirmed', function () {
      var status = this.get('status');
      var participationConfirmed = this.get('participationConfirmed');
      return status === 'accepted' && !participationConfirmed;
    }),
    isLost: Ember.computed.equal('status', 'lost'),
    isAwarded: Ember.computed.equal('status', 'awarded'),
    isDismissed: Ember.computed.equal('status', 'dismissed'),
    //  TODO: revise
    isEditAllowed: Ember.computed('status', function () {
      return this.get('status') === 'submitted';
    }),
    beautyContestInvitationDateCET: Ember.computed('beautyContestInvitationDate', function () {
      if (this.get('beautyContestInvitationDate')) {
        return (0, _moment.default)(this.get('beautyContestInvitationDate')).tz('Europe/Berlin').format('DD-MM-YYYY');
      }

      return '';
    }),
    financialQuotationData: Ember.computed('financialQuotationEvaluationData', function () {
      var financialData = this.get('financialQuotationEvaluationData');
      var camelizedFinancialData = {};

      for (var key in financialData) {
        if (financialData.hasOwnProperty(key)) {
          camelizedFinancialData[key.camelize()] = financialData[key];
        }
      }

      return camelizedFinancialData;
    })
  });

  _exports.default = _default;
});