define("cb-ui/controllers/app/project/client/beauty-contest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var invitationTextDefault = "<p>Dear participant</p><p>We have evaluated your submitted proposal and would like to give you the change to pitch in a final beauty contest with few other selected consultants. In this pitch we would like you to personally present your project approach and get to know you and part of your team. For us it would be very important to meet your proposed project manager.</p> <p> Please find below the address, date and time for the beauty contest.</p>";

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service('notify'),
    quote: null,
    proposals: new Ember.A(),
    beautyContestAddress: null,
    quoteCompany: null,
    beautyContestInvitationAddress: null,
    proposalsWithoutBeautyContestInvitation: new Ember.A(),
    beautyContestInvitationText: invitationTextDefault,
    proposalsWithoutBeautyContestInvitationObserver: Ember.observer('proposals.[]', 'proposals.@each.beautyContestInvitationStatus', function () {
      var proposalsWithoutInvitation = this.get('proposals').filter(function (proposal) {
        var beautyContestInvitationStatus = proposal.get('beautyContestInvitationStatus');
        return !beautyContestInvitationStatus || !beautyContestInvitationStatus.trim();
      });
      var proposals = new Ember.A();
      proposalsWithoutInvitation.forEach(function (proposal) {
        proposals.addObject({
          proposal: proposal,
          isChecked: false
        });
      });
      this.set('proposalsWithoutBeautyContestInvitation', proposals);
    }),
    proposalsWithBeautyContestInvitation: Ember.computed('proposals.[]', 'proposals.@each.beautyContestInvitationStatus', function () {
      return this.get('proposals').filter(function (proposal) {
        var beautyContestInvitationStatus = proposal.get('beautyContestInvitationStatus');
        return beautyContestInvitationStatus === 'accepted' || beautyContestInvitationStatus === 'invitation sent' || proposal.get('declinedInvitationBeautyContest');
      });
    }),
    isCurrentUserReadOnly: Ember.computed('proposal.metadata.user.role', function () {
      return this.get('proposal.metadata.user.role') === 'readonly';
    }),
    proposalsBeautyContestInvitationRemoved: Ember.computed('proposals.[]', 'proposals.@each.beautyContestInvitationStatus', function () {
      return this.get('proposals').filterBy('beautyContestInvitationStatus', 'not invited');
    }),
    beautyContestAddressObserver: Ember.observer('quote', 'quoteCompany', 'quoteCompany.companyName', 'quoteCompany.globalAddress', 'quoteCompany.addresses', function () {
      var companyName = this.get('quoteCompany.companyName') ? this.get('quoteCompany.companyName') : '';
      var addressBeautyContest = '<p>Please meet us at: </p>' + '<div>' + companyName + '</div>';
      var globalAddress = this.get('quoteCompany.globalAddress');

      if (globalAddress) {
        globalAddress.get('country').then(function (country) {
          addressBeautyContest += '<div>' + globalAddress.get('addressLine-1') + '</div><div>' + globalAddress.get('city') + '</div><div>' + country.get('commonNameEn') + '</div>';
        });
      }

      this.set('beautyContestInvitationAddress', addressBeautyContest);
    }),
    actions: {
      toggleDisplay: function toggleDisplay(containerId) {
        var $container = Ember.$('#' + containerId);
        $container.prev('h4').find('span').toggleClass('glyphicon-triangle-bottom glyphicon-triangle-right');
        $container.fadeToggle();
      },
      sendInvitations: function sendInvitations() {
        var _this = this;

        var proposalItems = this.get('proposalsWithoutBeautyContestInvitation').filterBy('isChecked', true);

        if (proposalItems.get('length')) {
          if (confirm('Are you sure you want to send the invitations')) {
            var savePromises = [];
            proposalItems.forEach(function (item) {
              var proposal = item.proposal;
              proposal.set('beautyContestInvitationStatus', 'invitation sent');
              proposal.set('beautyContestInvitationText', _this.get('beautyContestInvitationText'));
              proposal.set('beautyContestInvitationAddress', _this.get('beautyContestInvitationAddress'));
              savePromises.push(proposal.save());
            });
            Ember.RSVP.all(savePromises).then(function () {
              _this.get('notify').success('Invitations sent successfully');

              proposalItems.forEach(function (item) {
                var proposal = item.proposal;
                proposal.reload();
              });
            }).catch(function () {
              proposalItems.forEach(function (item) {
                var proposal = item.proposal;
                proposal.rollbackAttributes();
              });

              _this.get('notify').error('Invalid data');
            });
          }
        }
      },
      removeInvitation: function removeInvitation(proposal) {
        if (confirm('Are you sure you want to delete the invitation ?')) {
          proposal.set('beautyContestInvitationStatus', 'not invited');
          proposal.save(function () {
            proposal.reload();
          });
        }
      },
      openDatepicker: function openDatepicker(datepickerId) {
        var selector = '#' + datepickerId.toString() + '.start-date-picker';
        Ember.$(selector).focus();
      }
    }
  });

  _exports.default = _default;
});