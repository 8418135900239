define("cb-ui/controllers/app/project/proposal/result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    resultData: null,
    currentUser: null,
    userProposalRole: null,
    userIsProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    isCurrentUserReadOnly: Ember.computed('proposal.metadata.user.role', function () {
      return this.get('proposal.metadata.user.role') === 'readonly';
    })
  });

  _exports.default = _default;
});