define("cb-ui/controllers/app/project/proposal/financial-quotation", ["exports", "ember-ajax", "cb-ui/config/environment"], function (_exports, _emberAjax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberController$ext;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var referenceTypes = ['% of Fee', 'Fixed Fee'];

  var _default = Ember.Controller.extend((_EmberController$ext = {
    notify: Ember.inject.service(),
    proposal: null,
    quote: null,
    currenUser: null,
    showUserStatusModal: false,
    showOrganizationWorkerRolesModal: false,
    userStatus: null,
    isProposalConsultant: null,
    userProposalRole: null,
    isProposalAdmin: Ember.computed.equal('userProposalRole.role', 'admin'),
    //technical proposal
    localDocuments: new Ember.A(),
    localReferences: new Ember.A(),
    localTeamMembers: new Ember.A(),
    localWorkPackages: new Ember.A(),
    uploadedFileType: null,
    otherFileType: null,
    //financial quotation
    timeMaterialsFee: null,
    timeMaterialsAnalysis: null,
    timeMaterialsConcept: null,
    timeMaterialsImplementation: null,
    timeMaterialsAudit: null,
    timeMaterialSupplementsValue: null,
    fixedFeesFee: null,
    fixedFeeSupplementsValue: null,
    projectPhases: new Ember.A(),
    localTimeMaterials: new Ember.A(),
    localTimeMaterialSupplements: new Ember.A(),
    localFixedFees: new Ember.A(),
    //flag for documents validation
    documentsAreValid: null,
    //flag to know if it's save or submit proposal
    isForSubmitProposal: null,
    // flag which shows if total fee is greater than 0
    totalProjectFeeIsValid: true,
    timeMaterialsPaymentPattern: null,
    fixedFeePaymentPattern: null,
    referenceOptions: referenceTypes,
    paymentPatternOptions: Ember.computed('intl.locale.[]', function () {
      return [{
        value: 'weekly',
        label: 'app.projectClient.evaluation.label.paymentOptions.weekly'
      }, {
        value: 'bi weekly',
        label: 'app.projectClient.evaluation.label.paymentOptions.biweekly'
      }, {
        value: 'monthly',
        label: 'app.projectClient.evaluation.label.paymentOptions.monthly'
      }, {
        value: 'quarterly',
        label: 'app.projectClient.evaluation.label.paymentOptions.quarterly'
      }];
    }),
    timeMaterialsPaymentPatternObserver: Ember.observer('timeMaterialsPaymentPattern', function () {
      this.get('proposal').set('timeMaterialsPaymentPattern', this.get('timeMaterialsPaymentPattern.value'));
    }),
    isCurrentUserReadOnly: Ember.computed('proposal.metadata.user.role', function () {
      return this.get('proposal.metadata.user.role') === 'readonly';
    }),
    fixedFeePaymentPatternObserver: Ember.observer('fixedFeePaymentPattern', function () {
      this.get('proposal').set('fixedFeePaymentPattern', this.get('fixedFeePaymentPattern.value'));
    }),
    analysisDaysInputDisabled: true,
    conceptDaysInputDisabled: true,
    implementationDaysInputDisabled: true,
    auditDaysInputDisabled: true,
    isProposalSaving: false,
    timeMaterialsSupplements: Ember.computed.filterBy('localTimeMaterialSupplements', 'type', 'time_material'),
    fixedFeeSupplements: Ember.computed.filterBy('localTimeMaterialSupplements', 'type', 'fixed_fee'),
    saveButtonClasses: Ember.computed('media.isMobile', 'media.isTablet', 'proposal.status', 'quote.status', function () {
      if (this.get('proposal.consultantUser.id') === this.get('currentUser.id')) {
        this.set('isProposalConsultant', true);
      }

      var classes = 'btn btn-white';

      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        classes += ' m-b-sm';
      }

      if (!(this.get('proposal.isAccepted') || this.get('proposal.isOpened')) || this.get('quote.status') === 'on hold') {
        classes += ' disabled';
      }

      return classes;
    }),
    saveSubmitButtonClasses: Ember.computed('media.isMobile', 'media.isTablet', 'proposal.status', 'quote.status', function () {
      var classes = 'btn btn-primary';

      if (this.get('media.isMobile') || this.get('media.isTablet')) {
        classes += ' m-b-sm';
      }

      if (!(this.get('proposal.isAccepted') || this.get('proposal.isOpened')) || this.get('quote.status') === 'on hold') {
        classes += ' disabled inactive';
      }

      return classes;
    }),
    proposalStatusObserver: Ember.observer('proposal.isSubmitted', function () {
      if (this.get('proposal.isSubmitted')) {
        this.setProperties({
          analysisDaysInputDisabled: true,
          conceptDaysInputDisabled: true,
          implementationDaysInputDisabled: true,
          auditDaysInputDisabled: true
        });
      }
    })
  }, _defineProperty(_EmberController$ext, "proposalStatusObserver", Ember.observer('quote.status', function () {
    if (this.get('quote.status') === 'on hold') {
      this.setProperties({
        analysisDaysInputDisabled: true,
        conceptDaysInputDisabled: true,
        implementationDaysInputDisabled: true,
        auditDaysInputDisabled: true
      });
    }
  })), _defineProperty(_EmberController$ext, "timeMaterialsAnalysisObserver", Ember.observer('localTimeMaterials.@each.analysisDays', 'localTimeMaterials.@each.rate', function () {
    // sum(analysisDays * rate)
    var localTimeMaterials = this.get('localTimeMaterials');
    var validatedLocalTimeMaterials = localTimeMaterials.filter(function (timeMaterial) {
      return timeMaterial && !isNaN(timeMaterial.get('analysisDays')) && !isNaN(timeMaterial.get('rate'));
    });
    this.set('timeMaterialsAnalysis', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      return prevValue + item.get('analysisDays') * item.get('rate');
    }, 0));
  })), _defineProperty(_EmberController$ext, "timeMaterialsConceptObserver", Ember.observer('localTimeMaterials.@each.conceptDays', 'localTimeMaterials.@each.rate', function () {
    // sum(conceptDays * rate)
    var localTimeMaterials = this.get('localTimeMaterials');
    var validatedLocalTimeMaterials = localTimeMaterials.filter(function (timeMaterial) {
      return timeMaterial && !isNaN(timeMaterial.get('conceptDays')) && !isNaN(timeMaterial.get('rate'));
    });
    this.set('timeMaterialsConcept', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      return prevValue + item.get('conceptDays') * item.get('rate');
    }, 0));
  })), _defineProperty(_EmberController$ext, "timeMaterialsImplementationObserver", Ember.observer('localTimeMaterials.@each.implementationDays', 'localTimeMaterials.@each.rate', function () {
    // sum(implementationDays * rate)
    var localTimeMaterials = this.get('localTimeMaterials');
    var validatedLocalTimeMaterials = localTimeMaterials.filter(function (timeMaterial) {
      return timeMaterial && !isNaN(timeMaterial.get('implementationDays')) && !isNaN(timeMaterial.get('rate'));
    });
    this.set('timeMaterialsImplementation', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      return prevValue + item.get('implementationDays') * item.get('rate');
    }, 0));
  })), _defineProperty(_EmberController$ext, "timeMaterialsAuditObserver", Ember.observer('localTimeMaterials.@each.auditDays', 'localTimeMaterials.@each.rate', function () {
    // sum(auditDays * rate)
    var localTimeMaterials = this.get('localTimeMaterials');
    var validatedLocalTimeMaterials = localTimeMaterials.filter(function (timeMaterial) {
      return timeMaterial && !isNaN(timeMaterial.get('auditDays')) && !isNaN(timeMaterial.get('rate'));
    });
    this.set('timeMaterialsAudit', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      return prevValue + item.get('auditDays') * item.get('rate');
    }, 0));
  })), _defineProperty(_EmberController$ext, "timeMaterialsFeeObserver", Ember.observer('localTimeMaterials.@each.fee', function () {
    // subtotal without suppliments
    var localTimeMaterials = this.get('localTimeMaterials');
    var validatedLocalTimeMaterials = localTimeMaterials.filter(function (timeMaterial) {
      return timeMaterial && !isNaN(timeMaterial.get('fee'));
    });
    this.set('timeMaterialsFee', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      return prevValue + item.get('fee');
    }, 0));
  })), _defineProperty(_EmberController$ext, "timeMaterialSupplementsValueObserver", Ember.observer('timeMaterialsSupplements.@each.percentage', 'timeMaterialsSupplements.@each.fee', 'timeMaterialsFee', function () {
    var timeMaterialsSupplements = this.get('timeMaterialsSupplements');
    var validatedLocalTimeMaterials = timeMaterialsSupplements.filter(function (tmSupplement) {
      return tmSupplement && (!isNaN(tmSupplement.get('percentage')) && tmSupplement.get('reference') === '% of Fee' || !isNaN(tmSupplement.get('fee')) && tmSupplement.get('reference') === 'Fixed Fee');
    });
    var timeMaterialsFee = this.get('timeMaterialsFee');
    this.set('timeMaterialSupplementsValue', validatedLocalTimeMaterials.reduce(function (prevValue, item) {
      var itemReference = item.get('reference');

      if (itemReference === '% of Fee') {
        if (timeMaterialsFee && !isNaN(timeMaterialsFee)) {
          return prevValue + item.get('percentage') * timeMaterialsFee / 100;
        }

        return prevValue;
      } else if (itemReference === 'Fixed Fee') {
        return prevValue + parseFloat(item.get('fee'));
      }
    }, 0));
  })), _defineProperty(_EmberController$ext, "totalTimeMaterials", Ember.computed('timeMaterialsFee', 'timeMaterialSupplementsValue', 'proposal.timeMaterialsRebate', function () {
    var timeMaterialsFee = this.get('timeMaterialsFee');
    var timeMaterialSupplementsValue = this.get('timeMaterialSupplementsValue');
    var total = 0;

    if (!isNaN(timeMaterialsFee)) {
      total += timeMaterialsFee;
    }

    if (!isNaN(timeMaterialSupplementsValue)) {
      total += timeMaterialSupplementsValue;
    }

    if (!isNaN(this.get('proposal.timeMaterialsRebate'))) {
      total -= this.get('proposal.timeMaterialsRebate');
    }

    return total;
  })), _defineProperty(_EmberController$ext, "fixedFeesFeeObserver", Ember.observer('localFixedFees.[]', 'localFixedFees.@each.fee', 'localFixedFees.@each.numberOfUnits', function () {
    var localFixedFees = this.get('localFixedFees');
    var validatedFixedFees = localFixedFees.filter(function (fixedFee) {
      return fixedFee && !isNaN(fixedFee.get('fee')) && !isNaN(fixedFee.get('numberOfUnits'));
    });
    this.set('fixedFeesFee', validatedFixedFees.reduce(function (prevValue, item) {
      return prevValue + parseFloat(item.get('fee')) * parseFloat(item.get('numberOfUnits'));
    }, 0));
  })), _defineProperty(_EmberController$ext, "fixedFeeSupplementsValueObserver", Ember.observer('fixedFeeSupplements.@each.percentage', 'fixedFeeSupplements.@each.fee', 'fixedFeesFee', function () {
    var fixedFeeSupplements = this.get('fixedFeeSupplements');
    var validSupplements = fixedFeeSupplements.filter(function (ffSupplement) {
      return ffSupplement && (!isNaN(ffSupplement.get('percentage') && ffSupplement.get('reference') === '% of Fee') || !isNaN(ffSupplement.get('fee')) && ffSupplement.get('reference') === 'Fixed Fee');
    });
    var fixedFeesFee = this.get('fixedFeesFee');
    this.set('fixedFeeSupplementsValue', validSupplements.reduce(function (prevValue, item) {
      var itemReference = item.get('reference');

      if (itemReference === '% of Fee') {
        if (!isNaN(fixedFeesFee)) {
          return prevValue + item.get('percentage') * fixedFeesFee / 100;
        }

        return prevValue;
      } else if (itemReference === 'Fixed Fee') {
        return prevValue + parseFloat(item.get('fee'));
      }
    }, 0));
  })), _defineProperty(_EmberController$ext, "totalFixedFees", Ember.computed('fixedFeesFee', 'fixedFeeSupplementsValue', 'proposal.fixedFeeRebate', function () {
    var fee = this.get('fixedFeesFee');
    var supplementsValue = this.get('fixedFeeSupplementsValue');
    var total = 0;

    if (!isNaN(fee)) {
      total += fee;
    }

    if (!isNaN(supplementsValue)) {
      total += supplementsValue;
    }

    if (!isNaN(this.get('proposal.fixedFeeRebate'))) {
      total -= this.get('proposal.fixedFeeRebate');
    }

    return total;
  })), _defineProperty(_EmberController$ext, "daysObserver", Ember.observer('localTimeMaterials.@each.analysisDays', 'localTimeMaterials.@each.conceptDays', 'localTimeMaterials.@each.implementationDays', 'localTimeMaterials.@each.auditDays', function () {
    var localTimeMaterials = this.get('localTimeMaterials'); // const analysisDaysInputDisabled = this.get('analysisDaysInputDisabled');
    // const conceptDaysInputDisabled = this.get('conceptDaysInputDisabled');
    // const implementationDaysInputDisabled = this.get('implementationDaysInputDisabled');
    // const auditDaysInputDisabled = this.get('auditDaysInputDisabled');

    localTimeMaterials.forEach(function (timeMaterial) {
      var analysisDays = 0;
      var conceptDays = 0;
      var implementationDays = 0;
      var auditDays = 0; // if (!this.get('analysisDaysInputDisabled')) {

      analysisDays = timeMaterial.get('analysisDays'); // }
      // if (!this.get('conceptDaysInputDisabled')) {

      conceptDays = timeMaterial.get('conceptDays'); // }
      // if (!this.get('implementationDaysInputDisabled')) {

      implementationDays = timeMaterial.get('implementationDays'); // }
      // if (!this.get('auditDaysInputDisabled')) {

      auditDays = timeMaterial.get('auditDays'); // }

      var days = 0; // if ((analysisDays >= 0) && !isNaN(analysisDays) && !analysisDaysInputDisabled) {

      if (analysisDays >= 0 && !isNaN(analysisDays)) {
        days += parseFloat(analysisDays) || 0;
      } // if ((conceptDays >= 0) && !isNaN(conceptDays) && !conceptDaysInputDisabled) {


      if (conceptDays >= 0 && !isNaN(conceptDays)) {
        days += parseFloat(conceptDays) || 0;
      }

      if (implementationDays >= 0 && !isNaN(implementationDays)) {
        days += parseFloat(implementationDays) || 0;
      } // if ((auditDays >= 0) && !isNaN(auditDays) && !auditDaysInputDisabled) {


      if (auditDays >= 0 && !isNaN(auditDays)) {
        days += parseFloat(auditDays) || 0;
      }

      timeMaterial.set('days', parseFloat(days.toFixed(2)));
    });
  })), _defineProperty(_EmberController$ext, "initDaysInputs", Ember.observer('analysisDaysInputDisabled', 'conceptDaysInputDisabled', 'implementationDaysInputDisabled', 'auditDaysInputDisabled', 'localTimeMaterials', function () {
    var analysisDaysInputDisabled = this.get('analysisDaysInputDisabled');
    var conceptDaysInputDisabled = this.get('conceptDaysInputDisabled');
    var implementationDaysInputDisabled = this.get('implementationDaysInputDisabled');
    var auditDaysInputDisabled = this.get('auditDaysInputDisabled');
    var localTimeMaterials = this.get('localTimeMaterials'); // localTimeMaterials.forEach(timeMaterial => {
    //   if (analysisDaysInputDisabled) {
    //     timeMaterial.set('analysisDays', null);
    //   }
    //   if (conceptDaysInputDisabled) {
    //     timeMaterial.set('conceptDays', null);
    //   }
    //   if (implementationDaysInputDisabled) {
    //     timeMaterial.set('implementationDays', null);
    //   }
    //   if (auditDaysInputDisabled) {
    //     timeMaterial.set('auditDays', null);
    //   }
    // });
  })), _defineProperty(_EmberController$ext, "loadProposalData", function loadProposalData(proposal) {
    var _this = this;

    //technical proposal
    var proposalDocuments = new Ember.A();
    var proposalReferences = new Ember.A();
    var proposalTeamMembers = new Ember.A();
    var proposalWorkPackages = new Ember.A();
    var dataLoadedPromises = [];
    var documentsPromise = proposal.get('documents');
    var referencesPromise = proposal.get('references');
    var teamMembersPromise = proposal.get('teamMembers');
    var workPackagesPromise = proposal.get('workPackages');
    dataLoadedPromises.push(documentsPromise);
    dataLoadedPromises.push(referencesPromise);
    dataLoadedPromises.push(teamMembersPromise);
    dataLoadedPromises.push(workPackagesPromise);
    documentsPromise.then(function (documents) {
      documents.forEach(function (document) {
        proposalDocuments.addObject(document);
      });
    });
    referencesPromise.then(function (references) {
      references.forEach(function (reference) {
        proposalReferences.addObject(reference);
      });
    });
    teamMembersPromise.then(function (teamMembers) {
      teamMembers.forEach(function (member) {
        proposalTeamMembers.addObject(member);
      });
    });
    workPackagesPromise.then(function (workPackages) {
      workPackages.forEach(function (workPackage) {
        proposalWorkPackages.addObject(workPackage);
      });
    }); //financial quotation

    var proposalTimeMaterials = new Ember.A();
    var proposalTimeMaterialSupplements = new Ember.A();
    var proposalFixedFees = new Ember.A();
    var timeMaterialsPromise = proposal.get('timeMaterials');
    var timeMaterialSupplementsPromise = proposal.get('timeMaterialSupplements');
    var fixedFeesPromise = proposal.get('fixedFees');
    dataLoadedPromises.push(timeMaterialsPromise);
    dataLoadedPromises.push(timeMaterialSupplementsPromise);
    dataLoadedPromises.push(fixedFeesPromise);
    timeMaterialsPromise.then(function (timeMaterials) {
      timeMaterials.forEach(function (timeMaterial) {
        proposalTimeMaterials.addObject(timeMaterial);
      });
    });
    timeMaterialSupplementsPromise.then(function (timeMaterialSupplements) {
      timeMaterialSupplements.forEach(function (tmSupplement) {
        proposalTimeMaterialSupplements.addObject(tmSupplement);
      });
    });
    fixedFeesPromise.then(function (fixedFees) {
      fixedFees.forEach(function (fee) {
        proposalFixedFees.addObject(fee);
      });
    });
    Ember.RSVP.all(dataLoadedPromises).then(function () {
      //technical proposal
      _this.set('localDocuments', proposalDocuments);

      _this.set('localReferences', proposalReferences);

      _this.set('localTeamMembers', proposalTeamMembers);

      _this.set('localWorkPackages', proposalWorkPackages);

      if (_this.get('localReferences.length') === 0) {
        _this.get('localReferences').addObject(_this.store.createRecord('reference', {
          clientName: '',
          projectName: '',
          achievements: ''
        }));
      }

      if (_this.get('localTeamMembers.length') === 0) {
        _this.get('localTeamMembers').addObject(_this.store.createRecord('team-member', {
          projectRole: '',
          name: '',
          specialization: ''
        }));
      }

      if (_this.get('localWorkPackages.length') === 0) {
        _this.get('localWorkPackages').addObject(_this.store.createRecord('work-package', {
          name: '',
          description: '',
          deliverables: ''
        }));
      } //financial quotation


      _this.set('localTimeMaterials', proposalTimeMaterials);

      _this.set('localTimeMaterialSupplements', proposalTimeMaterialSupplements);

      _this.set('localFixedFees', proposalFixedFees);

      var quote = _this.get('quote');

      if (quote.get('timeMaterial')) {
        if (_this.get('localTimeMaterials.length') === 0) {
          _this.get('localTimeMaterials').addObject(_this.store.createRecord('time-material', {
            role: '',
            description: '',
            rate: '',
            days: '',
            order: 1
          }));
        }
      } else if (quote.get('fixedFee')) {
        if (_this.get('localFixedFees.length') === 0) {
          _this.get('localFixedFees').addObject(_this.store.createRecord('fixed-fee', {
            projectPhase: _this.get('projectPhases') ? _this.get('projectPhases.firstObject') : null,
            //workPackage: '',
            //description: '',
            fee: 0,
            numberOfUnits: 1
          }));
        }
      }

      _this.daysObserver();
    }).catch(function () {
      _this.get('notify').error(_this.get('intl').t('notifyMessages.proposalDetailsNotLoaded').toString());
    });
  }), _defineProperty(_EmberController$ext, "fillValuesWithZeroTimeSupplements", function fillValuesWithZeroTimeSupplements() {
    // If left empty, a field should be automatically filled with a “0,00”.
    // This should be true for all numeric fields in this interface.
    // for timeSupplement case
    var localTimeMaterials = this.get('localTimeMaterials');
    var localTimeMaterialSupplements = this.get('localTimeMaterialSupplements');
    var proposal = this.get('proposal');

    if (!proposal.get('durationInWeeks')) {
      proposal.set('durationInWeeks', 0);
    }

    if (!proposal.get('timeMaterialsRebate')) {
      proposal.set('timeMaterialsRebate', 0);
    }

    if (!proposal.get('timeMaterialsRetainer')) {
      proposal.set('timeMaterialsRetainer', 0);
    }

    localTimeMaterials.forEach(function (timeMaterial) {
      var rate = timeMaterial.get('rate');
      var analysisDays = timeMaterial.get('analysisDays');
      var conceptDays = timeMaterial.get('conceptDays');
      var implementationDays = timeMaterial.get('implementationDays');
      var auditDays = timeMaterial.get('auditDays');

      if (!rate) {
        timeMaterial.set('rate', 0);
      }

      if (!analysisDays) {
        timeMaterial.set('analysisDays', 0);
      }

      if (!conceptDays) {
        timeMaterial.set('conceptDays', 0);
      }

      if (!implementationDays) {
        timeMaterial.set('implementationDays', 0);
      }

      if (!auditDays) {
        timeMaterial.set('auditDays', 0);
      }
    });
    localTimeMaterialSupplements.forEach(function (tmSupplement) {
      var percentage = tmSupplement.get('percentage');
      var fee = tmSupplement.get('fee');

      if (!percentage && !fee) {
        tmSupplement.set('percentage', 0);
        tmSupplement.set('fee', 0);
      }
    }); //ready and tested for the time supplemnt
  }), _defineProperty(_EmberController$ext, "fillValuesWithZeroFixedFee", function fillValuesWithZeroFixedFee() {
    // If left empty, a field should be automatically filled with a “0,00”.
    // This should be true for all numeric fields in this interface.
    // for FixedFee
    var proposal = this.get('proposal');

    if (!proposal.get('fixedFeeRebate')) {
      proposal.set('fixedFeeRebate', 0);
    }

    if (!proposal.get('fixedFeeRetainer')) {
      proposal.set('fixedFeeRetainer', 0);
    }

    var localTimeMaterialSupplements = this.get('localTimeMaterialSupplements');
    localTimeMaterialSupplements.forEach(function (tmSupplement) {
      var percentage = tmSupplement.get('percentage');
      var fee = tmSupplement.get('fee');

      if (!percentage && !fee) {
        tmSupplement.set('percentage', 0);
        tmSupplement.set('fee', 0);
      }
    });
  }), _defineProperty(_EmberController$ext, "saveProposal", function saveProposal(isForSubmit) {
    var _this2 = this;

    var proposal = this.get('proposal');
    var localTimeMaterials = this.get('localTimeMaterials');
    var localTimeMaterialSupplements = this.get('localTimeMaterialSupplements');
    var localFixedFees = this.get('localFixedFees');
    var localReferences = this.get('localReferences');
    var localTeamMembers = this.get('localTeamMembers');
    var localWorkPackages = this.get('localWorkPackages');
    proposal.set('documents', this.get('localDocuments'));
    var proposalChildrenPromises = [];
    proposal.get('timeMaterials').then(function (timeMaterials) {
      var timeMaterialsCopy = timeMaterials.slice();
      timeMaterialsCopy.forEach(function (tm) {
        if (!localTimeMaterials.includes(tm)) {
          proposalChildrenPromises.push(tm.destroyRecord());
        }
      });
    });
    proposal.get('fixedFees').then(function (fixedFees) {
      var fixedFeesCopy = fixedFees.slice();
      fixedFeesCopy.forEach(function (fee) {
        if (!localFixedFees.includes(fee)) {
          proposalChildrenPromises.push(fee.destroyRecord());
        }
      });
    });
    proposal.get('timeMaterialSupplements').then(function (supplements) {
      var supplementsCopy = supplements.slice();
      supplementsCopy.forEach(function (supplement) {
        if (!localTimeMaterialSupplements.includes(supplement)) {
          proposalChildrenPromises.push(supplement.destroyRecord());
        }
      });
    });
    proposal.get('references').then(function (references) {
      var referencesCopy = references.slice();
      referencesCopy.forEach(function (reference) {
        if (!localReferences.includes(reference)) {
          proposalChildrenPromises.push(reference.destroyRecord());
        }
      });
    });
    proposal.get('teamMembers').then(function (members) {
      var membersCopy = members.slice();
      membersCopy.forEach(function (member) {
        if (!localTeamMembers.includes(member)) {
          proposalChildrenPromises.push(member.destroyRecord());
        }
      });
    });
    proposal.get('workPackages').then(function (workPackages) {
      var packagesCopy = workPackages.slice();
      packagesCopy.forEach(function (wp) {
        if (!localWorkPackages.includes(wp)) {
          proposalChildrenPromises.push(wp.destroyRecord());
        }
      });
    });
    localTimeMaterials.forEach(function (timeMaterial) {
      timeMaterial.set('proposal', proposal);
      proposalChildrenPromises.push(timeMaterial.save());
    });
    localTimeMaterialSupplements.forEach(function (tmSupplement) {
      tmSupplement.set('proposal', proposal);
      proposalChildrenPromises.push(tmSupplement.save());
    });
    localFixedFees.forEach(function (fixedFee) {
      fixedFee.set('proposal', proposal);
      proposalChildrenPromises.push(fixedFee.save());
    });
    localReferences.forEach(function (reference) {
      reference.set('proposal', proposal);
      proposalChildrenPromises.push(reference.save());
    });
    localTeamMembers.forEach(function (member) {
      member.set('proposal', proposal);
      proposalChildrenPromises.push(member.save());
    });
    localWorkPackages.forEach(function (workPackage) {
      workPackage.set('proposal', proposal);
      proposalChildrenPromises.push(workPackage.save());
    });
    return new Ember.RSVP.Promise(function (resolve, reject) {
      Ember.RSVP.all(proposalChildrenPromises).then(function () {
        proposal.set('saveForSubmit', isForSubmit);
        proposal.save().then(function () {
          proposal.reload();
          resolve();
        }).catch(function (err) {
          if (err.errors.length) {
            err.errors.forEach(function (e) {
              _this2.get('notify').error(e.detail);
            });
          }

          reject();
        });
      }).catch(function () {
        reject();
      });
    });
  }), _defineProperty(_EmberController$ext, "validateTimeMaterials", function validateTimeMaterials(isForSubmit) {
    var _this3 = this;

    var localTimeMaterials = this.get('localTimeMaterials');
    var dataIsValid = true;
    localTimeMaterials.forEach(function (timeMaterial) {
      var role = timeMaterial.get('role');
      var rate = timeMaterial.get('rate');
      var analysisDays = timeMaterial.get('analysisDays');
      var conceptDays = timeMaterial.get('conceptDays');
      var implementationDays = timeMaterial.get('implementationDays');
      var auditDays = timeMaterial.get('auditDays');
      var description = timeMaterial.get('description');
      var organizationWorkerRole = timeMaterial.get('organizationWorkerRole').get('consultantRole');
      timeMaterial.get('errors').remove('role');
      timeMaterial.get('errors').remove('rate');
      timeMaterial.get('errors').remove('days');
      timeMaterial.get('errors').remove('description');
      timeMaterial.get('errors').remove('organizationWorkerRole');

      if (isForSubmit) {
        if (!organizationWorkerRole) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('organizationWorkerRole', _this3.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!role || !role.trim()) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('role', _this3.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!description || !description.trim()) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('description', _this3.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!rate && rate !== 0) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('rate', _this3.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if ((rate || rate === 0) && !isNaN(rate) && rate <= 0) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('rate', _this3.get('intl').t('valueGreaterThan0').toString());
          dataIsValid = false;
        }

        if (isNaN(analysisDays) && isNaN(conceptDays) && isNaN(implementationDays) && isNaN(auditDays)) {
          timeMaterial.send('becomeDirty');
          timeMaterial.get('errors').add('days', _this3.get('intl').t('requiredField').toString());
          dataIsValid = false;

          if (!(_this3.get('media.isMobile') || _this3.get('media.isTablet'))) {
            if (!_this3.get('analysisDaysInputDisabled')) {
              timeMaterial.get('errors').add('analysisDays', _this3.get('intl').t('requiredField').toString());
            }

            if (!_this3.get('conceptDaysInputDisabled')) {
              timeMaterial.get('errors').add('conceptDays', _this3.get('intl').t('requiredField').toString());
            }

            if (!_this3.get('implementationDaysInputDisabled')) {
              timeMaterial.get('errors').add('implementationDays', _this3.get('intl').t('requiredField').toString());
            }

            if (!_this3.get('auditDaysInputDisabled')) {
              timeMaterial.get('errors').add('auditDays', _this3.get('intl').t('requiredField').toString());
            }
          } else {
            timeMaterial.get('errors').add('days', _this3.get('intl').t('requiredField').toString());
          }
        }
      }

      if (rate && isNaN(rate)) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('rate', _this3.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (rate && !isNaN(rate) && rate <= 0) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('rate', _this3.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }

      if (analysisDays && isNaN(analysisDays)) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('analysisDays', _this3.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (analysisDays && !isNaN(analysisDays) && analysisDays < 0) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('analysisDays', _this3.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }

      if (conceptDays && isNaN(conceptDays)) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('conceptDays', _this3.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (conceptDays && !isNaN(conceptDays) && conceptDays < 0) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('conceptDays', _this3.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }

      if (implementationDays && isNaN(implementationDays)) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('implementationDays', _this3.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (implementationDays && !isNaN(implementationDays) && implementationDays < 0) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('implementationDays', _this3.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }

      if (auditDays && isNaN(auditDays)) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('auditDays', _this3.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (auditDays && !isNaN(auditDays) && auditDays < 0) {
        timeMaterial.send('becomeDirty');
        timeMaterial.get('errors').add('auditDays', _this3.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }
    });

    if (isForSubmit && !localTimeMaterials.get('length')) {
      this.get('notify').error(this.get('intl').t('notifyMessages.addTMPeopleDays').toString());
      dataIsValid = false;
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validatePaymentTerms", function validatePaymentTerms(isForSubmit) {
    var quote = this.get('quote');
    var proposal = this.get('proposal');
    var dataIsValid = true;
    var paymentTermsProperty = '';

    if (quote.get('timeMaterial')) {
      paymentTermsProperty = 'timeMaterialsPaymentTerms';
    } else if (quote.get('fixedFee')) {
      paymentTermsProperty = 'fixedFeePaymentTerms';
    }

    proposal.get('errors').remove(paymentTermsProperty);

    if (isForSubmit && paymentTermsProperty) {
      var paymentTerms = proposal.get(paymentTermsProperty);

      if (!paymentTerms || !paymentTerms.trim()) {
        proposal.send('becomeDirty');
        proposal.get('errors').add(paymentTermsProperty, this.get('intl').t('requiredField').toString());
        dataIsValid = false;
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateDurationInWeeks", function validateDurationInWeeks(isForSubmit) {
    var dataIsValid = true;
    var proposal = this.get('proposal');
    proposal.get('errors').remove('durationInWeeks');

    if (isForSubmit) {
      var diw = proposal.get('durationInWeeks');

      if (diw === null || diw === "" || diw == 0) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('durationInWeeks', this.get('intl').t('requiredField').toString());
        dataIsValid = false;
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validatePaymentPatterns", function validatePaymentPatterns(isForSubmit) {
    var quote = this.get('quote');
    var proposal = this.get('proposal');
    var dataIsValid = true;
    var paymentPatternProperty = '';

    if (quote.get('timeMaterial')) {
      paymentPatternProperty = 'timeMaterialsPaymentPattern';
    } else if (quote.get('fixedFee')) {
      paymentPatternProperty = 'fixedFeePaymentPattern';
    }

    proposal.get('errors').remove(paymentPatternProperty);

    if (isForSubmit && paymentPatternProperty) {
      var paymentPattern = proposal.get(paymentPatternProperty);

      if (!paymentPattern) {
        proposal.send('becomeDirty');
        proposal.get('errors').add(paymentPatternProperty, this.get('intl').t('requiredField').toString());
        dataIsValid = false;
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateTMRebate", function validateTMRebate() {
    var dataIsValid = true;
    var proposal = this.get('proposal');
    var rebate = proposal.get('timeMaterialsRebate');

    if (rebate) {
      if (isNaN(rebate)) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('timeMaterialsRebate', this.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (!isNaN(rebate)) {
        if (rebate < 0) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('timeMaterialsRebate', this.get('intl').t('valueGreaterThan0').toString());
          dataIsValid = false;
        }

        if (parseFloat(rebate) > parseFloat(this.get('totalTimeMaterials')) + parseFloat(rebate)) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('timeMaterialsRebate', this.get('intl').t('notifyMessages.invalidRebate').toString());
          dataIsValid = false;
        }
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateTMRetainer", function validateTMRetainer() {
    var dataIsValid = true;
    var proposal = this.get('proposal');
    var retainer = proposal.get('timeMaterialsRetainer');

    if (retainer) {
      if (isNaN(retainer)) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('timeMaterialsRetainer', this.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (!isNaN(retainer) && retainer < 0) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('timeMaterialsRetainer', this.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }

      if (!isNaN(retainer) && retainer > this.get('totalTimeMaterials')) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('timeMaterialsRetainer', this.get('intl').t('notifyMessages.invalidRetainer').toString());
        dataIsValid = false;
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateFixedFees", function validateFixedFees(isForSubmit) {
    var _this4 = this;

    var localFixedFees = this.get('localFixedFees');
    var dataIsValid = true;
    localFixedFees.forEach(function (fixedFee) {
      var fee = fixedFee.get('fee');
      fixedFee.get('errors').remove('projectPhase'); //fixedFee.get('errors').remove('workPackage');
      //fixedFee.get('errors').remove('description');

      fixedFee.get('errors').remove('fee');

      if (isForSubmit) {
        if (!fixedFee.get('projectPhase')) {
          fixedFee.send('becomeDirty');
          fixedFee.get('errors').add('projectPhase', _this4.get('intl').t('requiredField').toString());
          dataIsValid = false;
        } //if (!fixedFee.get('workPackage') || !fixedFee.get('workPackage').trim()) {
        //  fixedFee.send('becomeDirty');
        //  fixedFee.get('errors').add('workPackage', this.get('intl').t('requiredField').toString());
        //  dataIsValid = false;
        //}
        //if (!fixedFee.get('description') || !fixedFee.get('description').trim()) {
        //  fixedFee.send('becomeDirty');
        //  fixedFee.get('errors').add('description', this.get('intl').t('requiredField').toString());
        //  dataIsValid = false;
        //}


        if (!fee) {
          fixedFee.send('becomeDirty');
          fixedFee.get('errors').add('fee', _this4.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }
      }

      if (fee && isNaN(fee)) {
        fixedFee.send('becomeDirty');
        fixedFee.get('errors').add('fee', _this4.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (fee && !isNaN(fee) && fee <= 0) {
        fixedFee.send('becomeDirty');
        fixedFee.get('errors').add('fee', _this4.get('intl').t('valueGreaterThan0').toString());
        dataIsValid = false;
      }
    });

    if (isForSubmit && !localFixedFees.get('length')) {
      this.get('notify').error(this.get('intl').t('notifyMessages.atLeastOneFixedFee').toString());
      dataIsValid = false;
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateFixedFeeRebate", function validateFixedFeeRebate() {
    var dataIsValid = true;
    var proposal = this.get('proposal');
    proposal.get('errors').remove('fixedFeeRebate');
    var rebate = proposal.get('fixedFeeRebate');

    if (rebate) {
      if (isNaN(rebate)) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('fixedFeeRebate', this.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (!isNaN(rebate)) {
        if (rebate < 0) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('fixedFeeRebate', this.get('intl').t('valueGreaterThan0').toString());
          dataIsValid = false;
        }

        if (parseFloat(rebate) > parseFloat(this.get('totalFixedFees')) + parseFloat(rebate)) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('fixedFeeRebate', this.get('intl').t('notifyMessages.invalidRebate').toString());
          dataIsValid = false;
        }
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateFixedFeeRetainer", function validateFixedFeeRetainer() {
    var dataIsValid = true;
    var proposal = this.get('proposal');
    proposal.get('errors').remove('fixedFeeRetainer');
    var retainer = proposal.get('fixedFeeRetainer');

    if (retainer) {
      if (isNaN(retainer)) {
        proposal.send('becomeDirty');
        proposal.get('errors').add('fixedFeeRetainer', this.get('intl').t('numberField').toString());
        dataIsValid = false;
      }

      if (!isNaN(retainer)) {
        if (retainer < 0) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('fixedFeeRetainer', this.get('intl').t('valueGreaterThan0').toString());
          dataIsValid = false;
        }

        if (retainer > this.get('totalFixedFees')) {
          proposal.send('becomeDirty');
          proposal.get('errors').add('fixedFeeRetainer', this.get('intl').t('notifyMessages.invalidRetainer').toString());
          dataIsValid = false;
        }
      }
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateTotalVolume", function validateTotalVolume() {
    this.set('totalProjectFeeIsValid', true);
    var dataIsValid = true;
    var quote = this.get('quote');
    var totalVolume = 0;

    if (quote.get('timeMaterial')) {
      totalVolume = this.get('totalTimeMaterials');
    } else if (quote.get('fixedFee')) {
      totalVolume = this.get('totalFixedFees');
    }

    if (totalVolume < 0) {
      dataIsValid = false;
      this.set('totalProjectFeeIsValid', false);
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateReferences", function validateReferences(isForSubmit) {
    var _this5 = this;

    var dataIsValid = true;
    var localReferences = this.get('localReferences');
    localReferences.forEach(function (reference) {
      reference.get('errors').remove('clientName');
      reference.get('errors').remove('projectName');
      reference.get('errors').remove('achievements');

      if (isForSubmit) {
        if (!reference.get('clientName') || !reference.get('clientName').trim()) {
          reference.send('becomeDirty');
          reference.get('errors').add('clientName', _this5.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!reference.get('projectName') || !reference.get('projectName').trim()) {
          reference.send('becomeDirty');
          reference.get('errors').add('projectName', _this5.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!reference.get('achievements') || !reference.get('achievements').trim()) {
          reference.send('becomeDirty');
          reference.get('errors').add('achievements', _this5.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }
      }
    });
    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateTeamMembers", function validateTeamMembers(isForSubmit) {
    var _this6 = this;

    var dataIsValid = true;
    var localTeamMembers = this.get('localTeamMembers');
    localTeamMembers.forEach(function (member) {
      member.get('errors').remove('projectRole');
      member.get('errors').remove('name');
      member.get('errors').remove('specialization');

      if (isForSubmit) {
        if (!member.get('projectRole') || !member.get('projectRole').trim()) {
          member.send('becomeDirty');
          member.get('errors').add('projectRole', _this6.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!member.get('name') || !member.get('name').trim()) {
          member.send('becomeDirty');
          member.get('errors').add('name', 'This field is required.');
          dataIsValid = false;
        }

        if (!member.get('specialization') || !member.get('specialization').trim()) {
          member.send('becomeDirty');
          member.get('errors').add('specialization', _this6.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }
      }
    });
    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateWorkPackages", function validateWorkPackages(isForSubmit) {
    var _this7 = this;

    var dataIsValid = true;
    var localWorkPackages = this.get('localWorkPackages');
    localWorkPackages.forEach(function (workPackage) {
      workPackage.get('errors').remove('name');
      workPackage.get('errors').remove('description');
      workPackage.get('errors').remove('deliverables');

      if (isForSubmit) {
        if (!workPackage.get('name') || !workPackage.get('name').trim()) {
          workPackage.send('becomeDirty');
          workPackage.get('errors').add('name', _this7.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!workPackage.get('description') || !workPackage.get('description').trim()) {
          workPackage.send('becomeDirty');
          workPackage.get('errors').add('description', _this7.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }

        if (!workPackage.get('deliverables') || !workPackage.get('deliverables').trim()) {
          workPackage.send('becomeDirty');
          workPackage.get('errors').add('deliverables', _this7.get('intl').t('requiredField').toString());
          dataIsValid = false;
        }
      }
    });
    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateDocumentsObserver", Ember.observer('localDocuments.length', 'isForSubmitProposal', function () {
    var localDocuments = this.get('localDocuments');
    var isForSubmitProposal = this.get('isForSubmitProposal'); //TODO: Refactor: This works only if proposalDocumentTypes are loaded on route

    var approachDocuments = localDocuments.filterBy('type.isApproachPresentation', true);

    if (!isForSubmitProposal) {
      this.set('documentsAreValid', true);
    } else {
      if (localDocuments.length && approachDocuments.get('length')) {
        this.set('documentsAreValid', true);
      } else {
        this.set('documentsAreValid', false);
      }
    }
  })), _defineProperty(_EmberController$ext, "validateDocuments", function validateDocuments(isForSubmit) {
    var _this8 = this;

    var dataIsValid = true;
    var localDocuments = this.get('localDocuments');
    var validPromise = new Ember.RSVP.Promise(function (resolve) {
      if (isForSubmit) {
        var documentTypePromises = [];
        var approachDocuments = localDocuments.filter(function (item) {
          var promise = new Ember.RSVP.Promise(function (resolveType) {
            item.get('type').then(function (documentType) {
              if (documentType.get('isApproachPresentation')) {
                return true;
              }

              resolveType();
            });
          });
          documentTypePromises.push(promise);
        });
        Ember.RSVP.all(documentTypePromises).then(function () {
          if (!approachDocuments.get('length')) {
            _this8.get('notify').error(_this8.get('intl').t('notifyMessages.atLeastOneApproachDoc').toString());

            dataIsValid = false;
          }

          resolve();
        });
      } else {
        resolve();
      }
    });
    validPromise.then(function () {
      return dataIsValid;
    });
  }), _defineProperty(_EmberController$ext, "validateExistenceOfTechnicalProposalEntries", function validateExistenceOfTechnicalProposalEntries() {
    var localReferences = this.get('localReferences');
    var localTeamMembers = this.get('localTeamMembers');
    var localWorkPackages = this.get('localWorkPackages');
    var dataIsValid = true;

    if (localReferences.length == 0) {
      dataIsValid = false;
      this.get('notify').error(this.get('intl').t('notifyMessages.atLeastOneReference').toString());
    }

    if (localTeamMembers.length == 0) {
      dataIsValid = false;
      this.get('notify').error(this.get('intl').t('notifyMessages.atLeastOneProjectTeamOverviewEntry').toString());
    }

    if (localWorkPackages.length == 0) {
      dataIsValid = false;
      this.get('notify').error(this.get('intl').t('notifyMessages.atLeastOneProjectOverviewEntry').toString());
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateTechnicalProposal", function validateTechnicalProposal(isForSubmit) {
    var referencesValid = this.validateReferences(isForSubmit);
    var teamMembersValid = this.validateTeamMembers(isForSubmit);
    var workPackagesValid = this.validateWorkPackages(isForSubmit); //const documentsValid = this.validateDocuments(isForSubmit);

    var documentsValid = this.get('documentsAreValid');
    return referencesValid && teamMembersValid && workPackagesValid && documentsValid;
  }), _defineProperty(_EmberController$ext, "validateTimeMaterialsSupplements", function validateTimeMaterialsSupplements(isForSubmit) {
    var _this9 = this;

    var dataIsValid = true;

    if (isForSubmit) {
      this.get('localTimeMaterialSupplements').forEach(function (supplement) {
        supplement.get('errors').remove('supplement');
        supplement.get('errors').remove('reference');

        if (!supplement.get('supplement') || !supplement.get('supplement').trim()) {
          //supplement is blank or null
          supplement.send('becomeDirty');
          supplement.get('errors').add('supplement', 'This field is required.');
          dataIsValid = false;
        }

        if (!supplement.get('reference') || !supplement.get('reference').trim()) {
          //reference is blank or null
          supplement.send('becomeDirty');
          supplement.get('errors').add('reference', 'This field is required.');
          dataIsValid = false;
        }

        var percentage = supplement.get('percentage');
        var fee = supplement.get('fee');

        if (supplement.get('reference') === '% of Fee') {
          if (!percentage && isNaN(percentage)) {
            //pecentage  is null or not a number
            supplement.send('becomeDirty');
            supplement.get('errors').add('percentage', 'This field is required.');
            dataIsValid = false;
          }

          if (!percentage && !isNaN(percentage) && percentage <= 0) {
            //pecentage  is null or not a number
            supplement.send('becomeDirty');
            supplement.get('errors').add('percentage', _this9.get('intl').t('valueGreaterThan0').toString());
            dataIsValid = false;
          }
        } else {
          if (!fee && isNaN(fee)) {
            //fee  is null or not a number
            supplement.send('becomeDirty');
            supplement.get('errors').add('fee', 'This field is required.');
            dataIsValid = false;
          }

          if (!fee && !isNaN(fee) && fee <= 0) {
            //pecentage  is null or not a number
            supplement.send('becomeDirty');
            supplement.get('errors').add('fee', _this9.get('intl').t('valueGreaterThan0').toString());
            dataIsValid = false;
          }
        }
      });
    }

    return dataIsValid;
  }), _defineProperty(_EmberController$ext, "validateFinancialProposal", function validateFinancialProposal(isForSubmit) {
    var quote = this.get('quote');
    var paymentTermsValid = this.validatePaymentTerms(isForSubmit);
    var paymentPatternsValid = this.validatePaymentPatterns(isForSubmit);
    var projectVolumeValid = this.validateTotalVolume();

    if (quote.get('timeMaterial')) {
      var timeMaterialSupplementsValid = this.validateTimeMaterialsSupplements(isForSubmit);
      var durationInWeeksValid = this.validateDurationInWeeks(isForSubmit);
      var timeMaterialsValid = this.validateTimeMaterials(isForSubmit);
      var tmRebateValid = this.validateTMRebate();
      var tmRetainerValid = this.validateTMRetainer();
      return timeMaterialSupplementsValid && timeMaterialsValid && tmRebateValid && tmRetainerValid && paymentTermsValid && paymentPatternsValid && projectVolumeValid && durationInWeeksValid;
    } else if (quote.get('fixedFee')) {
      // there are no timeMaterial on  fixedFee proposal
      var fixedFeeSupplementsValid = this.validateTimeMaterialsSupplements(isForSubmit);

      var _durationInWeeksValid = this.validateDurationInWeeks(isForSubmit);

      var fixedFeesValid = this.validateFixedFees(isForSubmit);
      var fixedFeeRebateValid = this.validateFixedFeeRebate();
      var fixedFeeRetainerValid = this.validateFixedFeeRetainer();
      return fixedFeeSupplementsValid && fixedFeesValid && fixedFeeRebateValid && fixedFeeRetainerValid && paymentTermsValid && paymentPatternsValid && projectVolumeValid && _durationInWeeksValid;
    } else {
      this.get('notify').error('Invalid project');
      return false;
    }
  }), _defineProperty(_EmberController$ext, "validateProposal", function validateProposal(isForSubmit) {
    var financialProposalIsValid = this.validateFinancialProposal(isForSubmit);
    var technicalProposalIsValid = this.validateTechnicalProposal(isForSubmit);
    return financialProposalIsValid && technicalProposalIsValid;
  }), _defineProperty(_EmberController$ext, "actions", {
    confirmGoBack: function confirmGoBack() {
      if (confirm('Do you want to leave this screen without saving? ')) {
        var proposal = this.get('proposal');
        var quote = this.get('quote');
        this.transitionToRoute('app.project.proposal.questions', quote, proposal);
      }
    },
    updateObjectAttributeInput: function updateObjectAttributeInput(object, attr, unmasked, masked) {
      object.set(attr, parseFloat(unmasked));
    },
    navbarTransitionToComponent: function navbarTransitionToComponent(componentId) {
      Ember.run.next(function () {
        Ember.$('html, body').animate({
          scrollTop: Ember.$('#' + componentId).offset().top - 200
        });
      });
    },
    addTimeMaterial: function addTimeMaterial() {
      var localTimeMaterials = this.get('localTimeMaterials');
      var nextOrder = -1;

      if (!localTimeMaterials.length) {
        nextOrder = 1;
      } else {
        localTimeMaterials.mapBy('order').forEach(function (e) {
          nextOrder = e > nextOrder ? e : nextOrder;
        });
      }

      nextOrder = nextOrder + 1;
      localTimeMaterials.addObject(this.store.createRecord('time-material', {
        role: '',
        description: '',
        rate: '',
        days: '',
        order: nextOrder
      }));
      this.get('notify').success(this.get('intl').t('notifyMessages.timeMaterialAdded').toString());
    },
    addTimeMaterialSupplement: function addTimeMaterialSupplement() {
      var localTimeMaterialSupplements = this.get('localTimeMaterialSupplements');
      localTimeMaterialSupplements.addObject(this.store.createRecord('time-material-supplement', {
        type: 'time_material',
        reference: '% of Fee'
      }));
      this.get('notify').success(this.get('intl').t('notifyMessages.timeMaterialSupplementAdded').toString());
    },
    addFixedFee: function addFixedFee() {
      var localFixedFees = this.get('localFixedFees');
      localFixedFees.addObject(this.store.createRecord('fixed-fee', {
        projectPhase: this.get('projectPhases') ? this.get('projectPhases.firstObject') : null,
        workPackage: '',
        description: '',
        fee: 0,
        numberOfUnits: 1
      }));
      this.get('notify').success(this.get('intl').t('notifyMessages.fixedFeeAdded').toString());
    },
    addFixedFeeSupplement: function addFixedFeeSupplement() {
      var localTimeMaterialSupplements = this.get('localTimeMaterialSupplements');
      localTimeMaterialSupplements.addObject(this.store.createRecord('time-material-supplement', {
        type: 'fixed_fee',
        reference: '% of Fee',
        supplement: '',
        description: '',
        percentage: '',
        fee: 0
      }));
      this.get('notify').success(this.get('intl').t('notifyMessages.fixedFeeSupplementAdded').toString());
    },
    addProposalDocument: function addProposalDocument(file) {
      var _this10 = this;

      this.store.find('proposal-document', file.id).then(function (currentFile) {
        var localDocuments = _this10.get('localDocuments');

        localDocuments.addObject(currentFile);

        _this10.get('notify').success(_this10.get('intl').t('notifyMessages.fileUploaded').toString());
      });
    },
    removeProposalDocument: function removeProposalDocument(file) {
      this.get('localDocuments').removeObject(file);
    },
    addReference: function addReference() {
      var localReferences = this.get('localReferences');
      localReferences.addObject(this.store.createRecord('reference', {
        clientName: '',
        projectName: '',
        achievements: ''
      }));
    },
    addTeamMember: function addTeamMember() {
      var localTeamMembers = this.get('localTeamMembers');
      localTeamMembers.addObject(this.store.createRecord('team-member', {
        projectRole: '',
        name: '',
        specialization: ''
      }));
    },
    addWorkPackage: function addWorkPackage() {
      var localWorkPackages = this.get('localWorkPackages');
      localWorkPackages.addObject(this.store.createRecord('work-package', {
        name: '',
        description: '',
        deliverables: ''
      }));
    },
    fileTypeChanged: function fileTypeChanged(value) {
      this.set('uploadedFileType', value);
      this.set('otherFileType', null);
    },
    remove: function remove(item) {
      if (item.constructor.modelName === "time-material") {
        this.get("localTimeMaterials").removeObject(item);
      }

      if (item.constructor.modelName === "fixed-fee") {
        this.get("localFixedFees").removeObject(item);
      }

      if (item.constructor.modelName === "time-material-supplement") {
        this.get("localTimeMaterialSupplements").removeObject(item);
      }

      if (item.constructor.modelName === "reference") {
        this.get("localReferences").removeObject(item);
      }

      if (item.constructor.modelName === "team-member") {
        this.get("localTeamMembers").removeObject(item);
      }

      if (item.constructor.modelName === "work-package") {
        this.get("localWorkPackages").removeObject(item);
      }
    },
    save: function save(callback) {
      var _this11 = this;

      if (!this.get('isProposalSaving')) {
        this.set('isProposalSaving', true);
        this.set('isForSubmitProposal', false);

        if (!this.get('documentsAreValid')) {
          this.get('notify').error(this.get('intl').t('notifyMessages.atLeastOneApproachDoc').toString());
        }

        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          if ((_this11.get('proposal.isAccepted') || _this11.get('proposal.isOpened')) && _this11.validateProposal()) {
            _this11.saveProposal(false).then(function () {
              _this11.get('notify').success(_this11.get('intl').t('notifyMessages.proposalSaved').toString());

              _this11.set('isProposalSaving', false);

              resolve();
            }).catch(function () {
              _this11.get('notify').error(_this11.get('intl').t('notifyMessages.resolveExistingError').toString());

              _this11.set('isProposalSaving', false);

              reject();
            });
          } else {
            _this11.get('notify').error(_this11.get('intl').t('notifyMessages.resolveExistingError').toString());

            _this11.set('isProposalSaving', false);

            reject();
          }
        });
        callback(promise);
      } else {
        //empty promise here
        callback(new Ember.RSVP.Promise(function (resolve, reject) {
          reject();
        }));
      }
    },
    discardChanges: function discardChanges() {
      if (this.get('proposal.isAccepted') || this.get('proposal.isOpened')) {
        var proposal = this.get('proposal');
        proposal.rollbackAttributes();
        this.get('localTimeMaterials').forEach(function (timeMaterial) {
          timeMaterial.rollbackAttributes();
        });
        this.get('localFixedFees').forEach(function (fixedFee) {
          fixedFee.rollbackAttributes();
        });
        this.get('localTimeMaterialSupplements').forEach(function (supplement) {
          supplement.rollbackAttributes();
        });
        this.get('localReferences').forEach(function (reference) {
          reference.rollbackAttributes();
        });
        this.get('localTeamMembers').forEach(function (member) {
          member.rollbackAttributes();
        });
        this.get('localWorkPackages').forEach(function (workPackage) {
          workPackage.rollbackAttributes();
        });
        this.loadProposalData(proposal);
      }
    },
    submitProposal: function submitProposal(callback) {
      var _this12 = this;

      if (!this.get('isProposalSaving')) {
        this.set('isProposalSaving', true);
        var promise = new Ember.RSVP.Promise(function (resolve, reject) {
          _this12.set('isForSubmitProposal', true);

          if (_this12.get('proposal.isAccepted') || _this12.get('proposal.isOpened')) {
            Ember.run.next(_this12, function () {
              if (!_this12.get('documentsAreValid')) {
                _this12.set('isProposalSaving', false);

                _this12.get('notify').error(_this12.get('intl').t('notifyMessages.atLeastOneApproachDoc').toString());
              }

              if (_this12.get('quote').get('timeMaterial')) {
                _this12.fillValuesWithZeroTimeSupplements();
              } else {
                _this12.fillValuesWithZeroFixedFee();
              }

              if (_this12.validateExistenceOfTechnicalProposalEntries()) {
                if (_this12.validateProposal(true)) {
                  _this12.saveProposal(true).then(function () {
                    _this12.get('notify').success(_this12.get('intl').t('notifyMessages.proposalSaved').toString());

                    var self = _this12;
                    return _this12.store.createRecord('user-profile-status', {
                      user: self.get('currentUser.id')
                    }).save().then(function (userStatus) {
                      _this12.set('userStatus', userStatus);

                      if (userStatus.get('emailConfirmed') && userStatus.get('profileCompleted')) {
                        _this12.send('openSubmitProposalModal');

                        _this12.set('isProposalSaving', false);

                        resolve();
                      } else {
                        _this12.set('showUserStatusModal', true);

                        _this12.set('isProposalSaving', false);

                        reject();
                      }
                    }).catch(function () {
                      _this12.set('isProposalSaving', false);

                      _this12.get('notify').error(_this12.get('intl').t('notifyMessages.errorOccurredReloadApp').toString());

                      reject();
                    });
                  }).catch(function () {
                    _this12.set('isProposalSaving', false); //todo: error for many to many


                    _this12.get('notify').error(_this12.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());

                    reject();
                  });
                } else {
                  _this12.set('isProposalSaving', false);

                  _this12.get('notify').error(_this12.get('intl').t('notifyMessages.resolveExistingError').toString());

                  reject();
                }
              } else {
                _this12.set('isProposalSaving', false);

                reject();
              }
            });
          } else {
            _this12.set('isProposalSaving', false);

            reject();
          }
        });
        callback(promise);
      } else {
        //empty promise here
        callback(new Ember.RSVP.Promise(function (resolve, reject) {
          reject();
        }));
      }
    },
    closeUserStatusModal: function closeUserStatusModal() {
      this.set('showUserStatusModal', false);
    },
    resendValidationEmail: function resendValidationEmail() {
      var _this13 = this;

      var url = _environment.default.backendAPI + '/api/send-validation-email/';
      (0, _emberAjax.default)(url, {
        type: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.get('session.data.authenticated.token')
        }
      }).then(function () {
        _this13.set('emailSent', true);
      });
    },
    showOrganizationWorkerRoleModalAction: function showOrganizationWorkerRoleModalAction() {
      this.set('showOrganizationWorkerRoleModal', true);
    },
    closeOrganizationWorkerRoleModalAction: function closeOrganizationWorkerRoleModalAction() {
      this.set('showOrganizationWorkerRoleModal', false);
    }
  }), _EmberController$ext));

  _exports.default = _default;
});