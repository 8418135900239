define("cb-ui/controllers/app/project/client/pitch", ["exports", "cb-ui/mixins/beauty-contest-invitation", "cb-ui/mixins/evaluation"], function (_exports, _beautyContestInvitation, _evaluation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_evaluation.default, _beautyContestInvitation.default, {
    notify: Ember.inject.service('notify'),
    quote: null,
    currentUser: null,
    proposals: new Ember.A(),
    consultantName: '',
    showWarningModal: false,
    userIsQuoteAdmin: Ember.computed.equal('userQuoteRole.role', 'admin'),
    timePickerStartOptions: Ember.computed(function () {
      var minutes = new Ember.A(['00', '15', '30', '45']);
      var timePickerOptions = new Ember.A();

      var _loop = function _loop(i) {
        var hour = i.toString();

        if (hour.length !== 2) {
          hour = '0' + hour;
        }

        minutes.forEach(function (item) {
          timePickerOptions.addObject(hour + ':' + item);
        });
      };

      for (var i = 0; i < 24; i++) {
        _loop(i);
      }

      return timePickerOptions;
    }),
    timePickerEndOptions: Ember.computed('beautyContestProposal.beautyContestInvitationStartTime', function () {
      var timePickerStartOptions = this.get('timePickerStartOptions').slice();
      timePickerStartOptions.addObject('24:00');
      var timePickerOptions = new Ember.A();

      if (this.get('beautyContestProposal') && this.get('beautyContestProposal.beautyContestInvitationStartTime')) {
        var startTime = this.get('beautyContestProposal.beautyContestInvitationStartTime');
        timePickerStartOptions.forEach(function (timeOption) {
          if (startTime < timeOption) {
            timePickerOptions.addObject(timeOption);
          }
        });
        return timePickerOptions;
      }

      return timePickerStartOptions;
    }),
    consultantNameObserver: Ember.observer('activeEvaluationCategory', 'activeEvaluation', 'activeEvaluation.proposal', function () {
      var _this = this;

      if (this.get('activeEvaluation')) {
        this.get('activeEvaluation.proposal').then(function (proposal) {
          proposal.get('consultantUser').then(function (consultantUser) {
            _this.set('consultantName', consultantUser.get('fullName'));
          });
        });
      }
    }),
    colspanEmptyColumns: Ember.computed('proposal.length', function () {
      return 5 - this.get('proposals.length');
    }),
    columns: Ember.computed('intl.locale.[]', 'proposals.length', function () {
      var columns = [{
        width: 200,
        heading: this.get('intl').t('app.projectClient.evaluation.label.beautyContest.details'),
        valuePath: 'title',
        isFixed: 'left'
      }];
      this.get('proposals').forEach(function (p) {
        columns.push({
          width: 150,
          valuePath: p.get('id'),
          company: p.get('company'),
          heading: '',
          headerComponent: 'ember-table/company-info'
        });
      });
      return columns;
    }),
    isCurrentUserReadOnly: Ember.computed('proposal.metadata.user.role', function () {
      return this.get('proposal.metadata.user.role') === 'readonly';
    }),
    rows: Ember.computed('intl.locale.[]', 'proposals.length', function () {
      var proposals = this.get('proposals');
      var pitchData = {
        title: this.get('intl').t('app.projectClient.evaluation.label.beautyContest.status')
      };
      proposals.forEach(function (p) {
        pitchData[p.get('id')] = {
          proposal: p,
          cellComponent: 'ember-table/pitch-presentation-cell'
        };
      });
      return [pitchData];
    }),
    actions: {
      presentationStartChanged: function presentationStartChanged(value) {
        var proposal = this.get('beautyContestProposal');

        if (proposal) {
          proposal.set('beautyContestInvitationStartTime', value);
          var beautyContestEndTime = proposal.get('beautyContestInvitationEndTime');

          if (beautyContestEndTime && beautyContestEndTime <= proposal.get('beautyContestInvitationStartTime')) {
            proposal.set('beautyContestInvitationEndTime', null);
          }
        }
      },
      presentationEndChanged: function presentationEndChanged(value) {
        var proposal = this.get('beautyContestProposal');

        if (proposal) {
          proposal.set('beautyContestInvitationEndTime', value);
          var beautyContestStartTime = proposal.get('beautyContestInvitationStartTime');

          if (beautyContestStartTime && beautyContestStartTime >= proposal.get('beautyContestInvitationEndTime')) {
            proposal.set('beautyContestInvitationStartTime', null);
          }
        }
      },
      closeWarningModal: function closeWarningModal() {
        this.set('showWarningModal', false);
      },
      triggerWarningModal: function triggerWarningModal() {
        this.set('showWarningModal', true);
      }
    }
  });

  _exports.default = _default;
});